<template>
  <b-modal
    id="modal-select-customer"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    no-close-on-backdrop
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal"
  >
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line px-2">
      <div
        class="d-flex align-items-center"
        style="cursor: pointer;"
        @click="$bvModal.hide('modal-select-customer'), $tours.cashierTour4.stop()"
      >
        <feather-icon
          size="23"
          class="text-dark mr-1 font-weight-bolder"
          icon="ArrowLeftIcon"
        />
        <h1 class="text-dark h6 font-weight-bolder mb-0">
          Pilih Pelanggan
        </h1>
      </div>
    </header>

    <!-- <b-row id="tour-add-customer" class="px-2 mx-0 justify-content-between">
      
    </b-row> -->
    <div style="height: 64px"></div>
    <b-row class="px-2 mx-0 mt-2">
      <b-col
        md="6"
        lg="7"
        class="pr-1 pl-0"
      >
        <b-input-group>
          <b-form-input
            v-model="filter.search"
            class="border-right-0 text-dark"
            placeholder="Cari nama pembeli"
            style="height: 52px;"
          />
          <b-input-group-append is-text>
            <feather-icon
              class="text-dark fw-bold-700"
              icon="SearchIcon"
              size="24"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col lg="5" md="6" class="align-self-center">
        <b-button id="button--add__pelanggan" class="btn-block" @click="$bvModal.show('modal-customer')">
          + Pelanggan baru
        </b-button>
      </b-col>
      <b-col
        lg="12"
        class="px-0 mt-3"
      >
        <h6 class="text-dark font-weight-bolder">
          Terakhir Dipilih
        </h6>
        <b-card
          no-body
          class="mb-0 mt-2"
          style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);border-radius: 12px;"
        >
          <b-form-radio
            v-model="selected"
            name="some-radios"
            :value="''"
          >
            <b-card-body class="d-flex flex-row align-items-center justify-content-between p-1">
              <div class="d-flex align-items-center">
                <b-avatar
                  variant="primary"
                  size="43"
                  text="PI"
                />
                <div class="ml-1">
                  <h1 class="h6 mb-0 text-dark font-weight-bolder">
                    Pelanggan
                  </h1>
                  <small class="text-dark text-darken-4 fw-bold-500">
                    Pilih jika tidak ingin menyertakan nama
                  </small>
                </div>
              </div>
            </b-card-body>
          </b-form-radio>
        </b-card>
        <div
          id="infinite-scroll"
          class="scroll--customer"
          style=""
        >
          <div
            v-if="isLoading"
            class="text-center mt-5"
          >
            <b-spinner variant="primary" />
          </div>
          <div
            v-for="(item, index) in result"
            :key="index"
          >
            <b-card
              :id="`tour-select-customer-${index}`"
              no-body
              class="mb-0 mt-2"
              style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);border-radius: 12px;"
              @click="tutorial"
            >
              <b-form-radio
                v-model="selected"
                name="some-radios"
                :value="item"
              >
                <b-card-body class="d-flex flex-row align-items-center justify-content-between p-1">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      variant="primary"
                      size="43"
                      text="PI"
                    />
                    <div class="ml-1">
                      <h1 class="h6 text-dark fw-bold-700 size14">
                        {{ item.name }} <span class="text-dark text-darken-5 size10">&bull;</span> <span class="text-dark text-darken-5 fw-bold-500">{{ item.phone_number }}</span>
                      </h1>
                      <div class="text-dark size10 mb-1" v-if="item.terms_of_payment === 'TOP'">
                        <span class="text-dark text-darken-5 fw-bold-500 size14 mr-1">Sisa limit piutang</span>
                        <span class="fw-bold-700 size14">{{  Math.max(0, item.terms_of_payment_limit - item.terms_of_payment_limit_used) | formatAmount }}</span>
                      </div>
                      <div class="badge">
                        <div class="badge-ritel">
                          {{ item.customer_type }}
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-form-radio>
            </b-card>
          </div>
          <div
            v-if="!isLoading && result && result.length == 0"
            class="text-center mt-5"
          >
            Data pelanggan tidak ditemukan.
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- <GroupButtonCartCashier
      :is_discount="false"
      :is_process="true"
    /> -->
    <ModalCustomer
      :is_edit="true"
      :get-data="getData"
    />
  </b-modal>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BFormInput, BRow, BCol, BCard, BModal, BInputGroup, VBToggle, BInputGroupAppend, BCardBody, BAvatar, BFormRadio, BSpinner, BButton,
} from 'bootstrap-vue'
import ModalCustomer from '@/components/Customer/Modal/ModalCustomer.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BModal,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardBody,
    BAvatar,
    BFormRadio,
    BButton,
    BSpinner,
    ModalCustomer,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      is_ritel: true,
      selected: '',
      result: {},
      page: 2,
      next_page_url: null,
      search: '',
      filter: {
        search: '',
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  watch: {
    // '$store.state.customer.customer': {
    //   handler(value) {
    //     console.log(value, ' adasdsadxzc')
    //     this.result = value.data
    //     this.next_page_url = value.next_page_url
    //   },
    //   deep: true,
    // },
    '$store.state.cashier.userData': {
      handler(value) {
        this.userData = value
      },
      deep: true,
    },
    selected(value) {
      localStorage.setItem('terms_of_payment_day', value.terms_of_payment_day || null)
      this.$store.commit('customer/setCustomer', value)
      this.$store.commit('checkout/setFormPayload', {
        customer_uuid: value.uuid || '',
      })
      localStorage.setItem('customer_data', JSON.stringify(value))
      localStorage.setItem('customer_uuid', value.uuid || '')
      localStorage.setItem('customer_type', value.customer_type || '')
      this.$bvModal.hide('modal-select-customer')
    },
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    // this.$store.commit('customer/setCustomer', {})
    setTimeout(() => {
      if (this.$route.query.uuid) {
        this.selected = JSON.parse(localStorage.getItem('customer')) || ''
      }
    }, 1000)
    // this.getNextPage()
  },
  methods: {
    tutorial() {
      this.$bvModal.hide('modal-select-customer')
      if (!this.userData.finish_tutorial) {
        this.$tours.cashierTour4.stop()
        this.$tours.cashierTour5.start()
      }
    },
    getNextPage() {
      const listElm = document.querySelector('#infinite-scroll')
      const that = this
      listElm.addEventListener('scroll', async () => {
        if (listElm.scrollTop + listElm.clientHeight === listElm.scrollHeight) {
          that.isLoading = true
          await that.$store.dispatch('customer/getData', {
            params: {
              // eslint-disable-next-line no-plusplus
              page: that.page++,
            },
          }).then(res => {
            res.data.data.data.forEach(element => {
              that.result.push(element)
            })
            that.next_page_url = res.data.data.next_page_url
            that.isLoading = false
            that.geUnreadNotification()
          })
            .catch(() => {
              that.isLoading = false
              // console.log('error notification')
            })
        }
      })
    },
    async getData() {
      const queryParams = this.filter
      this.isLoading = true
      this.$store.dispatch('customer/getData', {
        params: queryParams,
      })
        .then(result => {
          this.result = result.data.data.data
          this.next_page_url = result.data.data.next_page_url
          this.isLoading = false
        }).catch(err => {
          this.isLoading = false
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
.btn-group.btn-group__custom {
  padding: 7px;
  background: #EFF1F5;
  border-radius: 8px;

  .btn {
    color: #7A7F94 !important;
    border-color: transparent !important;
    background-color: transparent !important;

    &.btn-white {
      border-color: #FFFFFF !important;
      background-color: #FFFFFF !important;
      color: #2B324F;
      font-weight: 600;
      border-radius: 6px;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      box-shadow: none;
    }
  }
}
#modal-select-customer {
  .modal-dialog {
    margin-right: 0;
    width: 42vw;
    margin-top: 0;
    margin-bottom: 0;

    .modal-content {
      height: 100vh;
      overflow-y: scroll;

      .modal-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        header {
          padding-top: 2rem !important;
          padding-bottom: 2rem !important;
        }

        .custom-radio.custom-control {
          padding-left: 0;

          .custom-control-label {
            width: 100%;
            &::after, &::before {
              right: 1rem;
              top: 2rem;
              left: inherit;
            }
          }

        }

        .input-group {

          input {
            background: #E7E7ED;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border: none;
          }

          .input-group-append {
            .input-group-text {
              background-color: #E7E7ED;
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
              border: none;
            }
          }
        }

        .badge {
          padding: 0;

          .badge-ritel {
            background: #6B6C7E;
            border-radius: 12px;
            padding: 7px 12px;
          }
          .badge-non-ritel {
            background: #0B5FFF;
            border-radius: 12px;
            padding: 7px 12px;
          }
        }
      }
    }
  }
}
</style>
