<template>
  <div class="d-flex flex-column floating__button">
    <AddDiscount v-if="is_discount" />
    <b-row class="mx-0 bg-white">
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-center"
        style="gap: 12px; height: 72px"
        v-if="isLoadingValue"
      >
        <b-spinner variant="primary" />
        <p class="mb-0">Tunggu Sebentar</p>
      </b-col>
      <template v-else>
        <b-col
          class="px-0 align-self-center"
          :md="!canCheckoutData ? '12' : '4'"
          :lg="!canCheckoutData ? '12' : '2'"
        >
          <b-card
            v-b-modal.modal-save-transaction
            no-body
            :class="!canCheckoutData ? 'flex-row' : ''"
            class="mb-0 rounded-0 p-2"
            :style="'box-shadow: none;'"
            id="button--show__save--transaction"
          >
            <div
              :class="canCheckoutData ? 'mx-auto' : ''"
              class="rounded__frame print__logo text-center align-items-center button--save"
            >
              <feather-icon
                class="save--icon"
                icon="SaveIcon"
                style="color: #52cec2"
              />
            </div>
            <h4 class="text-primary" v-if="!canCheckoutData">
              Simpan di keranjang
            </h4>
          </b-card>
        </b-col>
        <b-col class="px-0" md="8" lg="10" v-if="canCheckoutData">
          <b-card
            no-body
            class="mb-0 rounded-0 p-2 h-100 d-flex justify-content-center"
            style="cursor: pointer"
            :class="mustOpenPettyCashData ? 'bg-disable' : 'bg-primary'"
            @click="!mustOpenPettyCashData ? directPage() : ''"
            id="button--direct__page"
          >
            <div class="d-flex align-items-center justify-content-between">
              <h6 id="tour-process-transaction" class="text-white mb-0 size14">
                <label
                  class="size12 fw-bold-500 text-white"
                  v-if="discountTotal > 0"
                  >Hemat {{ discountTotal | formatAmount }} 🎉</label
                >
                <br v-if="discountTotal > 0" />
                <div :class="mustOpenPettyCashData ? 'd-flex flex-column' : ''">
                  <span class="size24">{{
                    totalPrice || 0 | formatAmount
                  }}</span>
                  <span v-if="mustOpenPettyCashData"
                    >(Silahkan buka Kas Kecil terlebih dahulu)</span
                  >
                </div>
                <span
                  v-if="discountTotal > 0"
                  class="size12 fw-bold-500"
                  style="text-decoration: line-through"
                  >{{ subtotalCart | formatAmount }}</span
                >
              </h6>
              <div class="rounded__frame print__logo mr-0" style="opacity: 0">
                <b-img
                  width="17"
                  :src="require('/src/assets/images/icons/printer.svg')"
                />
              </div>
              <feather-icon
                class="text-white font-weight-bolder"
                icon="ArrowRightIcon"
                size="20"
              />
            </div>
          </b-card>
        </b-col>
      </template>
    </b-row>
    <ModalSaveTransaction />
    <ModalAdditionalCost :isChange="isChange" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { BRow, BCol, BCard, BImg, VBModal, BSpinner } from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";

import ModalSaveTransaction from "@/components/Cashier/Modal/SaveTransaction.vue";
import AddDiscount from "@/components/AddDiscount.vue";
import ModalAdditionalCost from "@/components/Cashier/Modal/AdditionalCost.vue";
// import TransactionModal from "@/components/Cashier/Modal/TransactionForm.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    ModalSaveTransaction,
    AddDiscount,
    ModalAdditionalCost,
    BSpinner,
    // TransactionModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  // props: ["is_discount", "is_process"],
  props: {
    is_discount: {
      type: Boolean,
    },
    is_process: {
      type: Boolean,
    },
    isLoadingValue: {
      type: Boolean,
      required: true,
    },
    canCheckoutData: {
      type: "",
      required: true,
    },
    mustOpenPettyCashData: {
      type: "",
      required: true,
    },
  },
  computed: {
    ...mapState({
      formAdditionalCost: (state) => state.cart.formAdditionalCost,
      totalAdditionalCost: (state) => state.cart.totalAdditionalCost,
    }),
  },
  data() {
    return {
      isLoading: false,
      discount: this.$store.state.cart.discountCart,
      discountType: this.$store.state.cart.discountType,
      totalPrice: this.$store.state.cart.totalPrice,
      discountTotal: this.$store.state.cart.discountTotal,
      subtotalCart: Number(this.$store.state.cart.subtotalCart),
      isChange: 0,
      // canCheckoutDataValue: null,
    };
  },
  watch: {
    // canCheckoutData(value) {
    //   console.log(value, "ini data dari sub parent");
    //   this.canCheckoutDataValue = value;
    // },
    /* eslint-disable func-names */
    "$store.state.cart.discountCart": function (value) {
      this.discount = value;
    },
    "$store.state.cart.totalPrice": function (value) {
      this.totalPrice = value;
    },
    "$store.state.cart.discountTotal": function (value) {
      this.discountTotal = value;
    },
    "$store.state.cart.subtotalCart": function (value) {
      this.subtotalCart = value;
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    };
  },
  mounted() {},
  methods: {
    ...mapActions("cart", ["removeAddtionalCost"]),
    async resetDiscount() {
      this.isLoading = true;
      const response = await this.removeAddtionalCost({
        uuid: this.$store.state.cart.cartUuid,
        payload: "",
      });

      if (response.status == 200) {
        successNotification(
          this,
          "Success",
          "Biaya tambahan berhasil dihapus."
        );
        this.isLoading = false;
        const item = response.data.data;
        this.$store.commit(
          "cart/SET_TOTAL_ADDITIONAL_COST",
          item.additional_fee_total
        );
        this.$store.commit("cart/setDiscountCart", item.discount_value);
        this.$store.commit("cart/setDiscountType", item.discount_type);
        this.$store.commit("cart/setDiscountTotal", item.discount_total);
        this.$store.commit("cart/setSubtotalCart", item.subtotal);
        this.$store.commit("cart/setTotalPrice", item.total);
        this.$store.commit("cart/setTotalBuy", item.items.length);
        this.$store.commit("cart/setCartUuid", item.uuid);
        this.$store.commit("cart/setResultCartActive", item);
        this.$store.commit("cashier/setProduct", item.items.length);
        localStorage.removeItem("additional_cost");
        localStorage.setItem("cartUuid", item.uuid);
        localStorage.setItem("cash_total_payment", item.total);
        localStorage.setItem("customer", JSON.stringify(item.customer));
      }
      this.isLoading = false;
    },
    directPage() {
      if (!this.$store.state.customer.customer.name) {
        localStorage.removeItem("customer_data");
      }
      localStorage.removeItem("payloadTransaksi");
      localStorage.removeItem("poSO");
      localStorage.removeItem("delivery_pickup");
      localStorage.removeItem("invoice_status");
      localStorage.setItem("is_full_payment", true);
      this.$router.push({
        name: "payment.index",
        query: {
          bonus:
            Math.round(
              parseFloat(
                this.$store.state.cart.discountTotal
                  .toString()
                  .replace(/,/g, "")
              )
            ) || 0,
          total:
            Math.round(
              parseFloat(this.totalPrice.toString().replace(/,/g, ""))
            ) || 0,
          uuid: this.$route.query.uuid,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.bg-disable {
  background: #6c757d;
}
.btn-discount {
  background: #ffe4e6;
  border-radius: 0 16px 0 0;
}
.rounded__frame {
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #d5f1ee;
  margin-right: 1rem;

  &.print__logo {
    background: #e4f8f6;
  }
}
.button--save {
  width: 50px;
  height: 50px;

  @media (max-height: 799px) {
    width: 30px;
    height: 30px;
  }

  .save--icon {
    width: 30px;

    @media (max-height: 799px) {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
